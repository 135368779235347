<template>
  <div class="contact">
    <CheckOutC/>
  </div>
</template>

<script>
import CheckOutC from '../components/CheckOut/CheckOutC.vue'
export default {
  name: "CheckOut",
  components: {
    CheckOutC
  }
}
</script>

<style>


</style>