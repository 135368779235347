import firebase from 'firebase/app'

require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");
require("firebase/functions");

var firebaseConfig = {
    apiKey: "AIzaSyCtGK30_OtnOyqQO2PwSisv62oizywmK0I",
    authDomain: "mosoqinn-7cbf8.firebaseapp.com",
    databaseURL: "https://mosoqinn-7cbf8.firebaseio.com",
    projectId: "mosoqinn-7cbf8",
    storageBucket: "mosoqinn-7cbf8.appspot.com",
    messagingSenderId: "842950418194",
    appId: "1:842950418194:web:8526b8bb11a3b5ffe6ddcb"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth=firebase.auth()
const db=firebase.firestore();
const storage=firebase.storage();
const functions=firebase.functions()

export{
    firebase,
    auth,
    db,
    storage,
    functions
}